/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-01-07",
    versionChannel: "nightly",
    buildDate: "2025-01-07T00:06:45.604Z",
    commitHash: "bd44f2def470d9b7fb4a12698a8b1ce45291d197",
};
